import React from 'react';

import Accordion from '../components/Accordion';
import PartnerWithUsBanner from '../components/Banners/PartnerWithUs';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { StatsHero } from '../components/InnerPages/StatsHero';
import CheckmarkIcon from '../images/Checkmark';
import CloseIcon from '../images/Close';
import Debt from '../images/Debt';
import EarnMoney from '../images/EarnMoney';
import IconFacebook from '../images/Facebook2';
import capitalOneImage from '../images/Home/capital-one-review.png';
import facebookImage from '../images/Home/facebook-template.png';
import incomeImage from '../images/Home/income-percentage.svg';
import instagramImage from '../images/Home/instagram-template.png';
import kashkickImage from '../images/Home/kashkick-review.png';
import legalShieldImage from '../images/Home/legal-shield-review.png';
import mapImage from '../images/Home/map.png';
import pinterestImage from '../images/Home/pinterest-template.png';
import postsDesktopImage from '../images/Home/posts-desktop.png';
import postsMobileImage from '../images/Home/posts-mobile.png';
import postsTabletImage from '../images/Home/posts-tablet.png';
import IconInstagram from '../images/Instagram2';
import Investment from '../images/Investment';
import Loans from '../images/Loans';
import IconPinterest from '../images/Pinterest2';
import Retirement from '../images/Retirement';
import SaveMoney from '../images/SaveMoney';
import IconSend from '../images/Send';
import IconTwitter from '../images/Twitter2';
import IconYoutube from '../images/Youtube2';

import style from '../styles/pages/stats.scss';

import cn from 'classnames';

interface ISignUpBannerProps {
  className?: string;
  location: any;
}

class Advertise extends React.Component<ISignUpBannerProps> {
  render() {
    const { location } = this.props;

    const accordionData = [
      {
        link: 'social-media', // This is the id of the section, e.g. '#social-media'
        title: 'Social Media',
        content:
          "We're your powerhouse partner in navigating the social media landscape, adept at conquering platforms from Facebook and Twitter to Instagram and Pinterest.  We have a blend of unparalleled creativity, campaign prowess, and optimization expertise that elevates your brand above the rest. But what truly sets us apart is our ability to tailor content that speaks directly to your audience, capturing their attention in our unique voice. For advertisers looking to make a real impact, teaming up with us means turning your brand into a social media sensation. Let's move your ideas into profit.",
        action: {
          link: 'packages-and-pricing',
          text: 'Grow Social Traffic',
        },
        images: [
          {
            src: facebookImage,
            alt: 'Facebook Template',
          },
          {
            src: instagramImage,
            alt: 'Instagram Template',
          },
          {
            src: pinterestImage,
            alt: 'Pinterest Template',
          },
        ],
      },
      {
        link: 'seo', // This is the id of the section, e.g. '#seo'
        title: 'SEO',
        content:
          "We work hard on creating content that is SEO-optimized, ensuring you receive the highest quality traffic that's more likely to convert. With SEO as one of our main focal points, we're confident that the visitors we attract are genuinely interested in what we have to offer.",
        action: {
          link: 'packages-and-pricing',
          text: 'Build Web Traffic',
        },
      },
      {
        link: 'product-reviews', // This is the id of the section, e.g. '#product-reviews'
        title: 'Product Reviews',
        content:
          "We're here to take a close look at your physical or digital product, review it, and then spread the word about it. This is something we've successfully done for many of our partners, helping them get the attention their products deserve. By working with us, you're not just getting a review; you're getting a team that knows how to highlight your product's best features and make sure it stands out.",
        action: {
          link: 'packages-and-pricing',
          text: 'Promote Your Product',
        },
        images: [
          {
            src: kashkickImage,
            alt: 'Kashkick Review',
          },
          {
            src: legalShieldImage,
            alt: 'Legal Shield Review',
          },
          {
            src: capitalOneImage,
            alt: 'Capital One Review',
          },
        ],
      },
      {
        link: 'video-ads', // This is the id of the section, e.g. '#video-ads'
        title: 'Video Ads',
        content:
          "Let's make some magic with video ads for your product! Picture this: engaging, lively content that grabs attention in an instant. We've been down this road before, crafting video campaigns that have really put our partners on the map. With us, it's not just about creating a video ad; it's about sharing your story in a way that resonates and converts viewers into fans. So, what do you say? Let's team up and bring your product's story to life in a video ad that's as unique and compelling as you are!",
        action: {
          link: 'packages-and-pricing',
          text: 'Promote with Video',
        },
        video: 'https://www.youtube.com/embed/Ih8SZlQTKE0',
      },
      {
        link: 'branded-blog-posts', // This is the id of the section, e.g. '#branded-blog-posts'
        title: 'Branded Blog Posts',
        content:
          "Imagine weaving the essence of your brand into stories that stick, through blog posts that do more than just inform—they inspire. That's where we shine. Our journey with branded blog posts has led our partners from mere visibility to memorable impact. With us, crafting a blog post is an art form, designed to bring out the nuances of your brand and connect deeply with readers. Ready to transform your brand's message into engaging narratives?",
        action: {
          link: 'packages-and-pricing',
          text: 'Promote Your Brand',
        },
        images: [
          {
            src: postsDesktopImage,
            alt: 'Desktop Posts',
          },
          {
            src: postsTabletImage,
            alt: 'Tablet Posts',
          },
          {
            src: postsMobileImage,
            alt: 'Mobile Posts',
          },
        ],
      },
      {
        link: 'backlinking', // This is the id of the section, e.g. '#backlinking'
        title: 'Backlinking',
        content:
          "Think of backlinking as the digital version of word-of-mouth for your brand, only it's online and can skyrocket your visibility like never before. That's our playground. We've mastered the art of crafting a backlink strategy that not only boosts your site's authority but also connects you with audiences that are genuinely interested in what you offer. With a proven track record of building meaningful connections and enhancing our partners' online presence, we're ready to do the same for you. Let's collaborate to weave a web of backlinks that elevates your brand's online footprint and draws in a crowd that's ready to engage.",
        action: {
          link: 'packages-and-pricing',
          text: 'Contact Us',
        },
      },
    ];

    return (
      <>
        <MetaData data={{}} location={location} title="Advertise" />
        <Layout isLightTheme mainClass={style.main}>
          <StatsHero />

          <div className="container">
            <section className={style.section}>
              <h2>Online Presence & Social Media</h2>
              <div className={style.numbers}>
                <div>
                  <IconSend />
                  <h4>9K</h4>
                  <p>email subscribers</p>
                </div>
                <div>
                  <IconFacebook />
                  <h4>3.3K</h4>
                  <p>facebook followers</p>
                </div>
                <div>
                  <IconTwitter />
                  <h4>14.7K</h4>
                  <p>twitter followers</p>
                </div>
                <div>
                  <IconInstagram />
                  <h4>1.2K</h4>
                  <p>instagram followers</p>
                </div>
                <div>
                  <IconYoutube />
                  <h4>2.2K</h4>
                  <p>youtube subscribers</p>
                </div>
                <div>
                  <IconPinterest />
                  <h4>5K</h4>
                  <p>pinterest followers</p>
                </div>
              </div>
            </section>
            <section className={style.section}>
              <h2>Our Demographics</h2>
              <div className={style.map}>
                <div className={style.progress}>
                  <div>
                    <h4>Gender Demographics:</h4>
                    <div className={style.item}>
                      <p>male (46.8%)</p>
                      <div className={style.progressBar}>
                        <div
                          className={style.progressValue}
                          style={{
                            width: '46.8%',
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.item}>
                      <p>female (53.2%)</p>
                      <div className={style.progressBar}>
                        <div
                          className={style.progressValue}
                          style={{
                            width: '53.2%',
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>Age Demographics:</h4>
                    <div className={style.item}>
                      <p>18+ (15%)</p>
                      <div className={style.progressBar}>
                        <div
                          className={style.progressValue}
                          style={{
                            width: '15%',
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.item}>
                      <p>25+ (80%)</p>
                      <div className={style.progressBar}>
                        <div
                          className={style.progressValue}
                          style={{
                            width: '80%',
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={style.item}>
                      <p>65+ (5%)</p>
                      <div className={style.progressBar}>
                        <div
                          className={style.progressValue}
                          style={{
                            width: '5%',
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <img className={style.image} src={mapImage} alt="Map" />
                </div>
              </div>
            </section>
            <section className={style.section}>
              <div className={style.income}>
                <div>
                  <h2>User Income Percentages</h2>
                  <div className={style.circleProgress}>
                    <div>
                      <img className={style.image} src={incomeImage} alt="Percentage" />
                    </div>
                    <div>
                      <p>
                        <span></span>25% ($30,000 annually)
                      </p>
                      <p>
                        <span></span>21% ($72,000 annually)
                      </p>
                      <p>
                        <span></span>15% ($36,000 annually)
                      </p>
                      <p>
                        <span></span>13% ($60,000 annually)
                      </p>
                      <p>
                        <span></span>11% ($42,000 annually)
                      </p>
                      <p>
                        <span></span>8% ($48,000 annually)
                      </p>
                      <p>
                        <span></span>5% ($54,000 annually)
                      </p>
                      <p>
                        <span></span>2% ($12,000 annually)
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h2>Main Blog Topics</h2>
                  <div className={style.blogTopics}>
                    <p>
                      <EarnMoney />
                      Earn Money
                    </p>
                    <p>
                      <SaveMoney />
                      Save Money
                    </p>
                    <p>
                      <Retirement />
                      Retirement
                    </p>
                    <p>
                      <Investment />
                      Investment
                    </p>
                    <p>
                      <Loans />
                      Loans
                    </p>
                    <p>
                      <Debt />
                      Debt
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section id="packages-and-pricing" className={style.packagesAndPricing}>
            <div className="container">
              <h2>Packages & Pricing</h2>
              <div className={style.packages}>
                <div className={style.package}>
                  <div className={style.header}>
                    <h5>Starter</h5>
                    <h3>$1,000</h3>
                    <h6>Social Media & Newsletter</h6>
                    <a
                      href="https://form.jotform.com/240867607779070"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Get Started
                    </a>
                  </div>
                  <div className={style.benefits}>
                    <p>
                      <CheckmarkIcon width={16} />
                      Featured on Social Accounts
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      TSR provides graphics & copy
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />3 Referral Links in TSR Blogs
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Sponsorship - TSR newsletter
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      1,000 word SEO driven article
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Custom video (one minute)
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Banner on TSR's homepage
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Custom Social Marketing
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      White Label Offerwall
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Monetize your traffic
                    </p>
                    <a
                      href="https://form.jotform.com/240867607779070"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className={cn(style.package, style.growth)}>
                  <div className={style.mostPopular}>Most Popular</div>
                  <div className={style.header}>
                    <h5>Growth</h5>
                    <h3>$2,500</h3>
                    <h6>+ Blog Post & Custom Video</h6>
                    <a
                      href="https://form.jotform.com/240866933194163"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Get Started
                    </a>
                  </div>
                  <div className={style.benefits}>
                    <p>
                      <CheckmarkIcon width={16} />
                      Featured on Social Accounts
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      TSR provides graphics & copy
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />3 Referral Links in TSR Blogs
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Sponsorship - TSR newsletter
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      1,000 word SEO driven article
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Custom video (one minute)
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Banner on TSR's homepage
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Custom Social Marketing
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      White Label Offerwall
                    </p>
                    <p>
                      <CloseIcon className={style.xIcon} width={12} />
                      Monetize your traffic
                    </p>
                    <a
                      href="https://form.jotform.com/240866933194163"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className={style.package}>
                  <div className={style.header}>
                    <h5>Enterprise</h5>
                    <h3>CUSTOM</h3>
                    <h6>Key Features Include</h6>
                    <a
                      href="https://calendly.com/partnershiptsr/30min"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Book a Call
                    </a>
                  </div>
                  <div className={style.benefits}>
                    <p>
                      <CheckmarkIcon width={16} />
                      Advanced SEO strategies
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      KPI driven social media ads
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Exclusive access to over 200k leads generated daily
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      Monetize your traffic with our decline path
                    </p>
                    <p>
                      <CheckmarkIcon width={16} />
                      White Label offerwall
                    </p>
                    <a
                      href="https://calendly.com/partnershiptsr/30min"
                      className={style.getStarted}
                      target="_blank"
                    >
                      Book a Call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={style.accordions}>
            <div className="container">
              <h3>Solutions We Offer</h3>
              <Accordion data={accordionData} />
            </div>
          </section>

          <PartnerWithUsBanner />
        </Layout>
      </>
    );
  }
}

export default Advertise;
