import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const TwitterIcon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02522 26.5041C15.1738 34.3383 30.1115 24.6962 29.8064 10.5344C29.8064 10.3838 29.8667 10.2369 29.9759 10.1314C31.6708 8.48549 32.2019 6.64747 31.9345 4.92998C31.9307 4.89608 31.8968 4.87725 31.8667 4.88855C31.3318 5.10323 29.7876 5.74729 28.4693 5.97328C28.2396 6.01094 28.0061 5.92432 27.8366 5.76613C23.3093 1.60799 16.1343 5.29532 17.1776 11.5928C17.1964 11.6945 17.1135 11.7924 17.0081 11.7887C12.0138 11.574 7.60708 9.29527 4.07416 5.21246C4.05533 5.19363 4.0252 5.1974 4.01013 5.21999C2.43953 8.24821 3.15892 11.2915 5.62217 13.3743C5.73516 13.4685 5.65983 13.6493 5.51294 13.6342C4.73705 13.5589 4.0026 13.3291 3.30581 12.9675C3.22672 12.9261 3.12879 12.9864 3.13256 13.0768C3.27568 16.041 4.85381 18.2255 7.96112 19.152C8.02892 19.1709 8.02892 19.2688 7.96112 19.2838C7.15887 19.4835 6.33402 19.491 5.49411 19.3441C5.43008 19.3328 5.37735 19.3968 5.39995 19.4571C6.40182 21.9994 8.2662 23.506 11.1099 23.7508C11.1777 23.7584 11.204 23.8412 11.1513 23.8827C8.57881 25.8337 5.35852 26.8092 2.04029 26.4363C2.00263 26.4325 1.98379 26.4815 2.01769 26.5003L2.02522 26.5041Z"
        stroke="#00825B"
        strokeWidth="2.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default React.memo(TwitterIcon);
