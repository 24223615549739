import React from 'react';

import ArrowMini from '../../images/ArrowMini';

import style from './PartnerWithUs.scss';

import cn from 'classnames';

interface IPartnerWithUsProps {
  className?: string;
}

export default function PartnerWithUs(props: IPartnerWithUsProps) {
  return (
    <div className={cn(style.partnerWithUs, props.className)}>
      <div className="container">
        <h2>Partner with us today</h2>
        <p>Let's jump on a call and secure a deal that will elevate your business.</p>
        <a
          href="https://calendly.com/partnershiptsr/30min"
          className={style.action}
          target="_blank"
        >
          Book a free call
          <ArrowMini rotate={-90} />
        </a>
        <p>Our pricing packages start at $1,000 per month</p>
      </div>
    </div>
  );
}
