import React, { useEffect, useRef, useState } from 'react';

import ArrowIcon from '../../images/ArrowBulk';

import style from './Accordion.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

type AccordionItemProps = {
  link: string;
  title: string;
  content: string;
  isOpen: boolean;
  onClick: () => void;
  action?: {
    link: string;
    text: string;
  };
  images?: {
    src: string;
    alt: string;
  }[];
  video?: string;
  linkInserts?: {
    title: string;
    price: string;
    description: string;
    action: {
      link: string;
      text: string;
    };
  }[];
};

//  accordionitem component
const AccordionItem = ({
  link,
  title,
  content,
  isOpen,
  onClick,
  action,
  images,
  video,
  linkInserts,
}: AccordionItemProps) => {
  const contentHeight = useRef<HTMLDivElement>(null);
  const [activeViewType, setActiveViewType] = useState(0);

  const handleDesktopClick = () => setActiveViewType(0);
  const handleTabletClick = () => setActiveViewType(1);
  const handleMobileClick = () => setActiveViewType(2);

  return (
    <div id={link} className={style.accordion}>
      <Link
        to={`advertise/#${link}`}
        className={cn(style.accordionButton, { [style.active]: isOpen })}
        onClick={onClick}
      >
        <h2>{title}</h2>
        <ArrowIcon rotate={isOpen ? -90 : 0} />
      </Link>

      <div ref={contentHeight} className={cn(style.content, { [style.active]: isOpen })}>
        <p>{content}</p>
        {action && (
          <Link to={`advertise/#${action.link}`} className={style.action} target="_blank">
            {action.text}
          </Link>
        )}
        {images && (
          <div
            className={cn(style.images, {
              [style.single]: link === 'branded-blog-posts',
            })}
          >
            {link === 'branded-blog-posts' && (
              <div className={style.viewType}>
                <button
                  className={cn({
                    [style.active]: activeViewType === 0,
                  })}
                  onClick={handleDesktopClick}
                >
                  Desktop
                </button>
                <button
                  className={cn({
                    [style.active]: activeViewType === 1,
                  })}
                  onClick={handleTabletClick}
                >
                  Tablet
                </button>
                <button
                  className={cn({
                    [style.active]: activeViewType === 2,
                  })}
                  onClick={handleMobileClick}
                >
                  Mobile
                </button>
              </div>
            )}
            {images.map((image, index) => (
              <img
                className={cn({
                  'd-none': activeViewType !== index && link === 'branded-blog-posts',
                })}
                key={index}
                src={image.src}
                alt={image.alt}
              />
            ))}
          </div>
        )}
        {video && (
          <div className={style.video}>
            <iframe
              width="100%"
              height="420"
              src={video}
              title="Seeking Alpha"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        )}
        {linkInserts && linkInserts.length > 0 && (
          <div className={style.linkInserts}>
            {linkInserts?.map((insert, index) => (
              <div key={index} className={style.linkInsert}>
                <h4>{insert.title}</h4>
                <h3>{insert.price}</h3>
                <p>{insert.description}</p>
                <a href={insert.action.link} className={style.action} target="_blank">
                  {insert.action.text}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

type AccordionProps = {
  data: {
    link: string;
    title: string;
    content: string;
    action?: {
      link: string;
      text: string;
    };
    images?: {
      src: string;
      alt: string;
    }[];
    video?: string;
    linkInserts?: {
      title: string;
      price: string;
      description: string;
      action: {
        link: string;
        text: string;
      };
    }[];
  }[];
};

const Accordion = ({ data }: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<string | null>(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setActiveIndex(hash.slice(1));
    }
  }, []);

  const handleItemClick = (index: string) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={style.accordions}>
      {data.map((item, index) => {
        const onClick = () => handleItemClick(item.link);

        return (
          <AccordionItem
            key={index}
            link={item.link}
            title={item.title}
            content={item.content}
            action={item.action}
            images={item.images}
            video={item.video}
            linkInserts={item.linkInserts}
            isOpen={activeIndex === item.link}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default Accordion;
