import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Facebook2Icon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5559 32.8725H12.8635C12.4226 32.8725 12.0635 32.5134 12.0635 32.0725V20.2396H7.9106C7.46971 20.2396 7.1106 19.8805 7.1106 19.4396V14.0103C7.1106 13.5694 7.46971 13.2103 7.9106 13.2103H12.0635V10.1703C12.0635 7.04137 12.9239 4.71248 14.6235 3.2476C16.675 1.4876 19.8822 0.961374 24.4297 1.64404C24.8564 1.70804 25.1764 2.08137 25.1764 2.5116V7.25115C25.1764 7.70271 24.8102 8.07248 24.355 8.07248H21.2013C19.9782 8.07248 19.3559 8.39604 19.3559 10.1738V13.2138H24.0848C24.3266 13.2138 24.5577 13.3169 24.7213 13.4983C24.8813 13.6796 24.9595 13.9214 24.9275 14.1632L24.2804 19.4858C24.227 19.916 23.8644 20.2396 23.4306 20.2396H19.3559V32.0725C19.3559 32.5134 18.9968 32.8725 18.5559 32.8725ZM13.3435 31.5925H18.0759V19.6956C18.0759 19.2903 18.4066 18.9596 18.8119 18.9596H23.0573L23.5977 14.4903H18.8119C18.4066 14.4903 18.0759 14.1596 18.0759 13.7543V10.1703C18.0759 7.89471 19.0999 6.78893 21.2013 6.78893H23.8964V2.86004C19.9248 2.30893 17.1622 2.75337 15.459 4.22182C14.0368 5.44849 13.3435 7.39337 13.3435 10.1738V13.7578C13.3435 14.1632 13.0128 14.4938 12.6075 14.4938H8.3906V18.9632H12.611C13.0164 18.9632 13.347 19.2938 13.347 19.6992V31.596L13.3435 31.5925Z"
        fill="#00825B"
        stroke="#00825B"
      />
    </svg>
  );
};

export default React.memo(Facebook2Icon);
