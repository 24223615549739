import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Youtube2Icon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0599 26.4433C10.7336 26.4433 7.51232 26.2407 6.0652 26.1198C3.35232 25.8709 1.7452 24.3882 1.40743 21.8389C1.30432 21.1455 1.20832 19.8193 1.11587 17.9135C1.05187 16.5695 1.05187 15.2078 1.11587 13.8638C1.20832 11.958 1.30432 10.6353 1.40743 9.93132C1.7452 7.3891 3.35232 5.90998 6.06165 5.65754C7.51587 5.53665 10.7372 5.33398 16.0279 5.33398C21.3185 5.33398 24.5754 5.53665 26.0225 5.65754C28.7354 5.90643 30.3425 7.3891 30.6803 9.93843C30.8083 10.8095 30.9114 12.5553 30.9719 13.8638C31.0359 15.2078 31.0359 16.5695 30.9719 17.9135C30.9114 19.222 30.8083 20.9678 30.6803 21.846C30.3461 24.3882 28.7354 25.8673 26.0296 26.1198C24.5754 26.2407 21.3541 26.4433 16.0634 26.4433H16.0599ZM16.0599 6.61398C10.7799 6.61398 7.6012 6.8131 6.17187 6.93398C4.0492 7.12954 2.93632 8.13932 2.67676 10.1091C2.58076 10.774 2.4812 12.0895 2.39587 13.9278C2.33543 15.2291 2.33543 16.5518 2.39587 17.8567C2.48476 19.6949 2.58076 21.0104 2.67676 21.6647C2.93632 23.6451 4.0492 24.6513 6.17898 24.8469C7.6012 24.9678 10.7834 25.1669 16.0279 25.1669H16.0634C21.3079 25.1669 24.4865 24.9678 25.9159 24.8469C28.0385 24.6513 29.1514 23.6415 29.411 21.6718C29.507 21.0069 29.6065 19.6878 29.6919 17.8531C29.7523 16.5518 29.7523 15.2291 29.6919 13.9242C29.603 12.0895 29.507 10.7704 29.411 10.1162C29.1514 8.13576 28.0385 7.12954 25.9088 6.93398C24.4865 6.8131 21.3043 6.61398 16.0599 6.61398Z"
        fill="#00825B"
        stroke="#00825B"
      />
      <path
        d="M13.0483 21.5043C12.9203 21.5043 12.7958 21.4723 12.6785 21.4083C12.4332 21.2732 12.2803 21.0172 12.2803 20.7327V11.026C12.2803 10.7452 12.4332 10.4856 12.6785 10.354C12.9238 10.2189 13.2261 10.2296 13.4607 10.3825L21.0412 15.2429C21.2616 15.3852 21.3932 15.6269 21.3932 15.89C21.3932 16.1532 21.2616 16.3949 21.0376 16.5372L13.4572 21.3834C13.3327 21.4652 13.1869 21.5043 13.0447 21.5043H13.0483ZM13.5603 11.9647V19.8012L19.6794 15.89L13.5603 11.9647Z"
        fill="#00825B"
        stroke="#00825B"
      />
    </svg>
  );
};

export default React.memo(Youtube2Icon);
