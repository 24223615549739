import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const RetirementIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1787 18H2L3.5 13H8.20178C9.06164 13 9.91587 12.8614 10.7316 12.5895L14.2684 11.4105C15.0841 11.1386 15.9384 11 16.7982 11H22.5L19.6517 15.9846C18.9395 17.2309 17.6141 18 16.1787 18Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        opacity="0.5"
        d="M5.31583 13.1111L6.97373 7M3.5 7H14.2639C15.0215 7 15.714 7.428 16.0528 8.10557L17.5 11"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default React.memo(RetirementIcon);
