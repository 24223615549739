import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
  rotate?: number;
}

const ArrowMiniIcon = (props: IIconProps) => {
  const { className, width = 24, height = 24, rotate = 0 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={{ transform: `rotate(${rotate}deg)` }}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3137 4.45753V19.5425M11.3137 19.5425L6.12828 14.357M11.3137 19.5425L16.4992 14.357"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default React.memo(ArrowMiniIcon);
