import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Instagram2Icon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5751 29.5541H9.4693C5.62219 29.5541 2.48975 26.4253 2.48975 22.5746V9.46881C2.48975 5.61815 5.61863 2.48926 9.4693 2.48926H22.5751C26.4222 2.48926 29.5546 5.61815 29.5546 9.46881V22.5746C29.5546 26.4217 26.4257 29.5541 22.5751 29.5541ZM9.4693 3.76926C6.32619 3.76926 3.76975 6.3257 3.76975 9.46881V22.5746C3.76975 25.7177 6.32619 28.2741 9.4693 28.2741H22.5751C25.7182 28.2741 28.2746 25.7177 28.2746 22.5746V9.46881C28.2746 6.3257 25.7182 3.76926 22.5751 3.76926H9.4693Z"
        fill="#00825B"
        stroke="#00825B"
      />
      <path
        d="M16.0226 22.4573C12.4742 22.4573 9.5835 19.5702 9.5835 16.0182C9.5835 12.4662 12.4706 9.5791 16.0226 9.5791C19.5746 9.5791 22.4617 12.4662 22.4617 16.0182C22.4617 19.5702 19.5746 22.4573 16.0226 22.4573ZM16.0226 10.8627C13.1782 10.8627 10.8635 13.1773 10.8635 16.0218C10.8635 18.8662 13.1782 21.1809 16.0226 21.1809C18.8671 21.1809 21.1817 18.8662 21.1817 16.0218C21.1817 13.1773 18.8671 10.8627 16.0226 10.8627Z"
        fill="#00825B"
        stroke="#00825B"
      />
      <path
        d="M23.595 9.76431C22.923 9.76431 22.3755 9.21675 22.3755 8.54475C22.3755 7.87275 22.923 7.3252 23.595 7.3252C24.267 7.3252 24.8146 7.87275 24.8146 8.54475C24.8146 9.21675 24.267 9.76431 23.595 9.76431ZM23.595 8.48431C23.563 8.48431 23.5346 8.51275 23.5346 8.54475C23.5346 8.61231 23.6555 8.61231 23.6555 8.54475C23.6555 8.51275 23.627 8.48431 23.595 8.48431Z"
        fill="#00825B"
        stroke="#00825B"
      />
    </svg>
  );
};

export default React.memo(Instagram2Icon);
