import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Pinterest2Icon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.95628 12.9301C7.63517 7.54639 12.4244 3.04406 18.9149 4.29091C25.6889 5.71881 24.9169 14.0778 22.1328 18.2693C19.4102 22.1021 14.8908 20.0491 15.7312 16.7697C16.5408 13.9105 18.037 10.3236 16.6569 8.64294C16.3119 8.22276 15.7927 7.99731 15.2529 7.91874C12.2195 7.47465 10.5012 11.6695 11.871 15.0992C11.9257 15.2359 11.9428 15.3896 11.9086 15.5331L9.67454 25.1116C9.64038 25.2517 9.61305 25.3951 9.59256 25.5386C9.25437 27.7864 9.24754 29.9009 9.63355 31.8549C9.66088 32.0017 9.85218 32.0496 9.94783 31.9403C11.3552 30.345 12.4518 28.4969 13.1862 26.3619C13.2375 26.2116 13.2853 26.0544 13.3229 25.8973L14.5048 21.3267C14.5185 21.2754 14.5834 21.2617 14.6175 21.3062C17.8969 25.6582 25.8597 23.0142 27.7829 16.1992C29.5592 9.64383 27.7214 4.32849 22.2353 1.99193C20.5853 1.28822 18.7919 0.977366 16.9951 1.00128C9.06991 1.11742 3.20117 6.96908 4.74863 14.5937C4.83403 15.0104 5.03558 15.6731 5.21662 16.0591C5.87933 17.4734 6.88365 18.4435 8.20565 18.9935C8.51651 19.1233 8.85811 18.9559 8.95718 18.6314L9.46616 16.9405C9.53449 16.715 9.48666 16.4691 9.33294 16.288C8.56092 15.3828 8.11683 14.457 7.95628 12.9335V12.9301Z"
        stroke="#00825B"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default React.memo(Pinterest2Icon);
