import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
  rotate?: number;
}

const ArrowBulkIcon = (props: IIconProps) => {
  const { className, width = 80, height = 80, rotate = 0 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={{ transform: `rotate(${rotate}deg)` }}
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70 40.5216H10M10 40.5216L33.3333 17.1885M10 40.5216L33.3333 63.8549"
        stroke="black"
        strokeWidth="12"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(ArrowBulkIcon);
