import React from 'react';

import mobileImage from '../../images/Home/mobile-phone.png';

import style from './StatsHero.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IStatsHeroProps {
  className?: string;
}

export const StatsHero = (props: IStatsHeroProps) => (
  <div className={cn(props.className, style.hero)}>
    <div className="container">
      <div className="row">
        <div className="col-md">
          <h1>Need more traffic?</h1>
          <Link to={'/stats/#packages-and-pricing'} className={style.action}>
            Partner with Us
          </Link>
        </div>
        <div className="col-md">
          <img className={style.image} src={mobileImage} alt="Mobile Phone" />
        </div>
      </div>
    </div>
  </div>
);
