import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const SendIcon = (props: IIconProps) => {
  const { height = 32, width = 32, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1407 29.6665C25.5434 29.6665 24.9567 29.4781 24.4518 29.1118L14.8589 22.0967C14.4358 21.7874 14.1656 21.3109 14.1158 20.7918C14.066 20.2692 14.2402 19.75 14.5958 19.366L20.5371 12.9412L12.1211 18.3385C10.8447 19.1563 9.2838 19.3696 7.83669 18.9287L2.73091 17.3714C1.93803 17.1332 1.40114 16.4434 1.35847 15.6149C1.31936 14.7865 1.78869 14.0505 2.55669 13.734L29.4296 2.67627C30.2545 2.33494 31.1647 2.46649 31.8651 3.02472C32.562 3.58294 32.8891 4.44338 32.7398 5.32516L28.9745 27.2807C28.8038 28.2656 28.1496 29.0763 27.2251 29.4532C26.8731 29.5954 26.5069 29.6665 26.1442 29.6665H26.1407ZM24.1389 9.23983C24.3345 9.23983 24.53 9.32516 24.6616 9.48161C24.882 9.74472 24.8749 10.1323 24.6402 10.3847L15.5309 20.2372C15.4207 20.3545 15.3709 20.5074 15.3851 20.6709C15.3994 20.8309 15.4811 20.9732 15.6091 21.0656L25.2056 28.0807C25.6536 28.4078 26.226 28.4789 26.738 28.2692C27.25 28.0629 27.6127 27.6114 27.7051 27.0674L31.4705 5.11183C31.5416 4.69583 31.3887 4.29049 31.058 4.02738C30.7309 3.76427 30.3007 3.70383 29.9096 3.86383L3.0438 14.918C2.64914 15.0816 2.63491 15.4478 2.63847 15.5545C2.64203 15.6612 2.6918 16.0238 3.10069 16.1483L8.20291 17.7056C9.29091 18.0363 10.4642 17.8763 11.4242 17.2612L23.7691 9.34649C23.8829 9.27538 24.0109 9.23983 24.1354 9.23983H24.1389Z"
        fill="#00825B"
        stroke="#00825B"
      />
    </svg>
  );
};

export default React.memo(SendIcon);
